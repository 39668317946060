@import url("https://rsms.me/inter/inter.css");
@import url("https://fonts.googleapis.com/css2?family=Antonio:wght@600&display=swap");

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100vw;
  height: 100vh;
  margin: 0;
  /* padding: 0; */
  overflow: hidden;
  /* display:flex; */
  /* justify-content: center;
  align-items: center; */
  background: linear-gradient(180deg, #e4ebfd 0%, #f6f6f6 80%);
}

.btn{
  z-index: -1;
  width: 100vw;
  height: 100vh;
  display:flex;
  justify-content: center;
  align-items: center;
}

.btn>button {
  /* margin-left: 20px;
  margin-top: 10px; */
  /* position:absolute; */
  /* margin-top: 30px; */
  /* background-color: red; */
  align-items: center;
  background-color: #fee6e3;
  border: 2px solid #111;
  border-radius: 8px;
  box-sizing: border-box;
  color: #111;
  cursor: pointer;
  display: flex;
  font-family: Inter,sans-serif;
  font-size: 14px;
  height: 48px;
  justify-content: center;
  line-height: 20px;
  max-width: 100%;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.btn>button:after {
  background-color: #111;
  border-radius: 8px;
  content: "";
  display: block;
  height: 48px;
  left: 0;
  width: 100%;
  position: absolute;
  top: -2px;
  transform: translate(4px, 4px);
  transition: transform .2s ease-out;
  z-index: -1;
}

.btn>button:hover:after {
  transform: translate(0, 0);
}

.btn>button:active {
  background-color: #ffdeda;
  outline: 0;
}

.btn>button:hover {
  outline: 0;
}

/* @media (min-width: 768px) {
  .overlay>button {
    padding: 0 40px;
  }
} */


